import React from 'react'; // eslint-disable-line no-unused-vars

// eslint-disable-next-line import/no-extraneous-dependencies
import ThemeTopLayout from 'gatsby-theme-material-ui-top-layout/src/components/top-layout';
import { CssBaseline } from '@mui/material';

const TopLayout = ({ children, theme }) => (
  <ThemeTopLayout theme={theme}>
    <CssBaseline />
    {children}
  </ThemeTopLayout>
);

export default TopLayout;
