import { createTheme, responsiveFontSizes } from '@mui/material';

// const FZ_RATIO = 4 / 3;
const FZ_RATIO = 1.15;

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(144, 151, 39)',
    },
    secondary: {
      main: '#ff950e',
    },
  },
  typography: {
    fontFamily: 'Lato, sans-serif',
    fontSize: 14,
    h6: { fontSize: 14 },
    h5: { fontSize: 14 * FZ_RATIO ** 1 },
    h4: { fontSize: 14 * FZ_RATIO ** 2 },
    h3: { fontSize: 14 * FZ_RATIO ** 3 },
    h2: { fontSize: 14 * FZ_RATIO ** 4 },
    h1: { fontSize: 14 * FZ_RATIO ** 5 },
  },
});
export default responsiveFontSizes(theme);
